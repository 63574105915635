import $ from 'jquery';
const domain = process.env.NODE_ENV === 'development' ? 'https://m.haodf.com/' : 'https://m.haodf.com/';
const request = function(url,success){
    $.ajax({
        url: domain+url,
        type: "GET",
        dataType: "jsonp", //指定服务器返回的数据
        success: function (res) {
            // console.log(res);
            success && success(res);
        }
    });
}

export default request;
